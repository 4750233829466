import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

import {
  CompaniesRepository,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
} from './companies.repository';
import {
  EsAdminPage,
  EventScheduleRepository,
  IActivityLog,
  IEventOption,
  IEventSchedule,
  IMarkingDot,
  IMonthWithEvents,
  IPageProperties,
  IYear,
  TimeZoneInfo,
} from './event-schedule.repository';
import { IComments } from './posts.repository';
import { FunnelStat, StatisticElement } from './event.repository';
import { saveAs } from 'file-saver';

const API = '/api/eventschedule';
const LogsAPI = '/api/activitylogs';
const GM_API_KEY = 'AIzaSyArK0PM_qJ07Bp3pBZ1ER2wlUNRInZ_eQc';

@Injectable({ providedIn: 'root' })
export class EventScheduleService extends BaseService<IEventSchedule> {
  constructor(http: HttpClient, public repo: EventScheduleRepository) {
    super(API, http, repo);
  }
  reloadMonths(
    year: number | null,
    search: string = '',
    type: string = '',
    isClientUser: boolean = false,
    isDraft: boolean = false,
    isPreview: boolean = false,
    clientId: string = ''
  ) {
    return this.loadMonthsWithEventsByYear(
      year,
      search,
      type,
      isClientUser,
      isDraft,
      isPreview,
      undefined,
      clientId
    ).pipe(
      tap((res) => {
        this.repo.updateMonths(res);
      })
    );
  }

  getESPage(
    getDataFromPageProps = false,
    year: number | null = null,
    search: string = '',
    type: string = ''
  ) {
    const query = [
      `getDataFromPageProps=${getDataFromPageProps}`,
      `year=${year}`,
      `search=${search}`,
      `type=${type}`,
    ];

    return this.http.get<EsAdminPage>(
      `${API}/loadEventSchedulePage/?${query.join('&')}`
    );
  }
  getPendingEventsCount() {
    return this.http.get<string>(`${API}/getPendingEventsCount`).pipe(
      tap((res) => {
        this.repo.updatePendingEventsCount(res);
      })
    );
  }
  loadTimeZone(tzInfo: Partial<TimeZoneInfo>) {
    return this.http.put<TimeZoneInfo>(`${API}/loadTimeZone`, tzInfo);
  }
  updateEventFinancials(event: Partial<IEventSchedule>) {
    return this.http.put<Partial<IEventSchedule>>(
      `${API}/updateEventFinancials`,
      event
    );
  }
  postActivityLog(post: Partial<IActivityLog>) {
    return this.http.post(`${LogsAPI}/post`, post);
  }
  loadActivityLogs(id: string) {
    return this.http.get<IActivityLog[]>(
      `${LogsAPI}/getEventActivityLogs/${id}`
    );
  }
  loadOneMonth(id: string) {
    return this.http.get<IPageProperties>(`${API}/loadOneMonth/${id}`);
  }
  getPageProperties() {
    return this.http.get<IPageProperties>(`${API}/getPageProperties`);
  }

  batchExportMonths(
    eventsIds: string[],
    tabSelected: string,
    filename = 'export'
  ) {
    var HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'json',
    };
    const query = [`tabSelected=${tabSelected}`];

    return this.http
      .put<Blob>(
        `${API}/batchExportMonths/?${query.join('&')}`,
        eventsIds,
        HTTPOptions
      )
      .pipe(
        tap((res) => {
          saveAs(res, `${filename}.csv`);
        })
      );
  }

  exportMonthsToCsv(mon: IMonthWithEvents, tabSelected: string) {
    var HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf',
      }),
      responseType: 'blob' as 'json',
    };
    const query = [`tabSelected=${tabSelected}`];

    return this.http.put<Blob>(
      `${API}/exportMonthsToCsv?${query.join('&')}`,
      mon,
      HTTPOptions
    );
  }
  getOwnClient(clientId: string = '') {
    const query = [`clientId=${clientId}`];

    return this.http.get<ICompanyMarker>(
      `${API}/getOwnClient?${query.join('&')}`
    );
  }
  setPageProperties(entity: IPageProperties) {
    return this.http.put<IPageProperties>(`${API}/setPageProperties`, entity);
  }
  loadOneWithoutTrack(id: string) {
    return this.http.get<IEventSchedule>(`${API}/${id}`);
  }
  checkReminder(id: string) {
    return this.http.get<IEventSchedule>(`${API}/checkReminder/${id}`);
  }
  getGoogleSheetsA4(id: string) {
    return this.http.get<any>('/api/googleSheets/getOne/' + id + '/A4:A4');
  }
  duplicate(id: string) {
    return this.http.get<IEventSchedule>(API + '/duplicate/' + id);
  }
  changeStatus(id: string, status: string, statusIndex: number) {
    return this.http.put<any>(API + `/changeStatus/${id}/${statusIndex}`, {
      status,
    });
  }

  changePayStats(id: string, payStats: string) {
    return this.http.put<any>(API + `/changePayStats/${id}/`, {
      payStats,
    });
  }

  deleteFunnelForEvent(eventId: string, editField: string) {
    const query = [`eventId=${eventId}`, `editField=${editField}`];
    return this.http.delete<any>(API + `/funnelForEvent/?${query.join('&')}`);
  }

  updateFunnelForEvent(
    eventId: string,
    editField: string,
    funnelId: string | null
  ) {
    const query = [
      `eventId=${eventId}`,
      `funnelId=${funnelId}`,
      `editField=${editField}`,
    ];

    return this.http.put<any>(
      API + `/updateFunnelForEvent/?${query.join('&')}`,
      {}
    );
  }
  loadTasks(type: 'upcoming' | 'backend' = 'upcoming', year: number = 0) {
    const query = [`type=${type}`, `year=${year}`];
    return this.http
      .get<IMonthWithEvents[]>(`${API}/upcomingCalls/?${query.join('&')}`)
      .pipe(
        tap((res) => {
          switch (type) {
            case 'upcoming': {
              this.repo.updateUpcomingMonths(res);

              break;
            }
            case 'backend': {
              this.repo.updateBackendMonths(res);

              break;
            }
          }
        })
      );
  }
  deleteSecond(id: string): Observable<void> {
    return this.http
      .delete<void>(`${API}/deleteSecond/${id}`)
      .pipe(this.tapReloadPage());
  }
  setSecondDateAsPrimary(id: string): Observable<void> {
    return this.http
      .delete<void>(`${API}/setSecondDateAsPrimary/${id}`)
      .pipe(this.tapReloadPage());
  }
  sortCustom(sortBy: SortState, search?: string, take: number = 25) {
    this.repo.setSort(sortBy);
    return this.reloadPage(search, take);
  }

  loadOneEventSchedule(id: string) {
    const query = [`id=${id}`];
    return this.http.get<IEventSchedule>(
      API + `/loadOneEventSchedule/?${query.join('&')}`
    );
  }
  loadOneEventScheduleWithIncuded(id: string) {
    const query = [`id=${id}`];
    return this.http.get<IEventSchedule>(
      API + `/loadOneEventScheduleWithIncuded/?${query.join('&')}`
    );
  }
  loadYears(
    isDraft: boolean = false,
    isPreview: boolean = false,
    disableFilters: boolean = false,
    clientId: string = ''
  ) {
    const query = [
      `isDraft=${isDraft}`,
      `isPreview=${isPreview}`,
      `disableFilters=${disableFilters}`,
      `clientId=${clientId}`,
    ];

    return this.http.get<number[]>(API + `/loadYears?${query.join('&')}`);
  }
  loadMarkingDots() {
    return this.http.get<IMarkingDot[]>(API + '/getMarkingDots');
  }
  setMarkingDot(entity: Partial<IMarkingDot>) {
    return this.http.patch<IMarkingDot>(API + '/setMarkingDot', entity);
  }
  postEventSchedule(entity: Partial<IEventSchedule>) {
    return this.http
      .post<IEventSchedule>(API + '/postEventSchedule/', entity)
      .pipe(
        tap((res) => {
          this.postActivityLog({
            eventScheduleId: res.id,
            type: 'Created',
            description: 'User Created Event',
          } as Partial<IActivityLog>).subscribe();
        })
      );
  }

  AddComment(comment: IComments) {
    return this.http.post<IComments>('/api/comment', comment);
  }
  updateEventSchedule(entity: Partial<IEventSchedule>) {
    return this.http.patch<IEventSchedule>(
      API + '/updateEventSchedule/',
      entity
    );
  }
  updateEventScheduleDot(
    id: string,
    type: string,
    doubleEvent: boolean,
    value: boolean
  ) {
    const query = [
      `id=${id}`,
      `type=${type}`,
      `doubleEvent=${doubleEvent}`,
      `value=${value}`,
    ];
    return this.http.get<IEventSchedule>(
      API + `/updateEventScheduleDot?${query.join('&')}`
    );
  }
  loadMonthsWithEventsByYear(
    year: number | null,
    search: string = '',
    type: string = '',
    isClientUser: boolean = false,
    isDraft: boolean = false,
    isPreview: boolean = false,
    disableFilters: boolean = false,
    clientId: string = ''
  ) {
    const query = [
      `search=${search}`,
      `year=${year}`,
      `type=${type}`,
      `isClientUser=${isClientUser}`,
      `isDraft=${isDraft}`,
      `isPreview=${isPreview}`,
      `disableFilters=${disableFilters}`,
      `clientId=${clientId}`,
    ];
    return this.http.get<IMonthWithEvents[]>(
      `${API}/loadMonthsWithEventsByYear?${query.join('&')}`
    );
  }

  getPosibleYears() {
    return this.http.get<number[]>(`${API}/getPosibleYears`);
  }
  getStatsByYear(year: number) {
    return this.http.get<StatisticElement[]>(
      `${API}/getStatisticsByYear/${year}`
    );
  }

  getCallersStats(year: number) {
    const query = [`year=${year}`];
    return this.http.get<FunnelStat[]>(
      `${API}/callerStats/?${query.join('&')}`
    );
  }

  rescheduleDate(id: string, date: number) {
    return this.http.put<IEventSchedule>(`${API}/rescheduleDate`, {
      id: id,
      date: date,
    });
  }

  loadOptions(clientIds: string[]) {
    return this.http.put<IEventOption[]>(API + '/loadOptions', {
      clientIds: clientIds,
    });
  }

  checkStatusRecursive(event: IEventSchedule, lastStatus: string): boolean {
    if (
      event.status === lastStatus ||
      (event.doubleEvent && event.statusD2 === lastStatus)
    ) {
      return true;
    }
    if (
      event.firstReschedules?.length &&
      event.firstReschedules.some((x) =>
        this.checkStatusRecursive(x, lastStatus)
      )
    ) {
      return true;
    }
    if (
      event.secondReschedules?.length &&
      event.secondReschedules.some((x) =>
        this.checkStatusRecursive(x, lastStatus)
      )
    ) {
      return true;
    }

    return false;
  }
}
