import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  AssignCallsRepository,
  WeekWithAssignCalls,
} from 'src/app/state/assignCalls.repository';
import { AssignCallsService } from 'src/app/state/assignCalls.service';
import { AssignerInfoRepository } from 'src/app/state/assignerInfo.repository';
import {
  EventScheduleRepository,
  IEventSchedule,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-assign-calls-table',
  templateUrl: './assign-calls-table.component.html',
  styleUrls: ['./assign-calls-table.component.scss'],
})
export class AssignCallsTableComponent {
  constructor(
    public esService: EventScheduleService,
    public assignCallsService: AssignCallsService,
    public assignCallsRepo: AssignCallsRepository,
    public esRepo: EventScheduleRepository,
    public assignerInfoRepo: AssignerInfoRepository
  ) {}
  editField: string = '';
  changeAssigner(event?: IEventSchedule) {
    this.assignCallsService
      .loadAssignCallsByYearWeek(
        this.assignCallsRepo.assignActiveYear ?? undefined
      )
      .subscribe((res) => {
        this.assignCallsRepo.updateUpcomingMonths(res ?? []);
        var newActiveWeek = res.find(
          (x) => x.name == this.assignCallsRepo.assignActiveWeek?.name
        );
        if (newActiveWeek)
          this.assignCallsRepo.setActiveWeekSelected(newActiveWeek);
      });
    this.mouseLeave();
  }
  manageAssignCaller($event: any, entity: IEventSchedule, editField: string) {
    this.editField = editField;
    this.assignCallsEvent = entity;
    let top = $event.pageY - 10;
    let left = $event.pageX - 10;

    if (window.outerHeight < top + 290) {
      top -= 280;
    }
    this.assignCallsLeft = Math.max(left, 0);
    this.assignCallsTop = Math.max(top, 0);
  }
  clearAssignCaller(id: string, editField: string) {
    this.esService.deleteFunnelForEvent(id, editField).subscribe((x) => {
      this.changeAssigner();
    });
  }
  assignCallsLeft: number | null = null;
  assignCallsTop: number | null = null;
  assignCallsEvent: IEventSchedule | null = null;
  mouseLeave() {
    this.assignCallsLeft = null;
    this.assignCallsTop = null;
    this.assignCallsEvent = null;
  }

  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  yearsOptions: DatalistOption[] | null = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];
  weeksOptions: DatalistOption[] | null = [];
  weekSelected: string | null = null;

  setMax100(ch: number, zn: number) {
    zn = Math.max(zn, 1);
    let val = Math.min((ch / zn) * 100, 100);
    return val.toFixed(0);
  }

  monthSelectedName: string | null | undefined = null;
  eventScheduleOnPopUp: IEventSchedule | null = null;
  eventSchedules: IEventSchedule[] = [];
  selectedYear: string | null = null;

  @Input() set years(value: number[] | null) {
    if (!value) {
      this.yearsOptions = null;
    } else {
      this.yearsOptions = value.map((x) => ({
        value: x.toString(),
        label: x.toString(),
      }));
    }
  }

  @Input() set weeks(value: WeekWithAssignCalls[] | null) {
    if (!value) {
      this.weeksOptions = null;
    } else {
      this.weeksOptions = value.map((x) => ({
        value: x,
        label: x.name ?? '',
      }));
    }
    if (value?.length && !this.assignCallsRepo.assignActiveWeek) {
      this.assignCallsRepo.setActiveWeekSelected(value[0]);
    }
  }
  selectActiveYear(year: string) {
    this.assignCallsRepo.setActiveWeekSelected(null);
    this.assignCallsService
      .loadAssignCallsByYearWeek(year)
      .subscribe((res) => this.assignCallsRepo.updateUpcomingMonths(res ?? []));
  }
  selectActiveWeek(week: WeekWithAssignCalls) {
    this.assignCallsRepo.setActiveWeekSelected(week);
  }
  ngOnInit(): void {
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
    this.esService.loadYears(false, false, true).subscribe((x) => {
      this.years = x;
      if (this.yearsOptions && this.assignCallsRepo.assignActiveYear == null)
        this.assignCallsRepo.assignActiveYear = this.yearsOptions[0].label;
      this.assignCallsRepo.assignActiveWeek = null;
    });
    this.assignCallsService
      .loadAssignCallsByYearWeek()
      .subscribe((res) => this.assignCallsRepo.updateUpcomingMonths(res ?? []));
  }

  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) modal.style.display = 'none';
  }
  showModal(id: string) {
    let event = this.eventSchedules?.find((x) => x.id == id);
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (event) {
      modal.style.display = 'block';
      this.eventScheduleOnPopUp = event;
    } else {
      console.log('cant find');
    }
  }
}
